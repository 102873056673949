import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import HeroInner from "../components/heroinner"
import TOS from "../components/termsofservice"

const TermsOfService = () => (
  <Layout>
    <SEO title="Terms Of Service" description="Codev Media Terms Of Service"/>
    <HeroInner title="Terms Of Service" />
    <TOS />
  </Layout>
)

export default TermsOfService;