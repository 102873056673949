import React from "react"
import { Container } from "react-bootstrap"

const TOS = () => {
  return (
    <Container className="py-5">
      <div>
          <div className="text-center">
        TERMS OF SERVICE
        <br />
        <br />
        Effective Date: January 1, 2020
        <br />
        <br />
        To review material modifications and their effective dates scroll to the
        bottom of the page.
        </div>
        <br />
        <br />
        1.
        <span style={{ paddingLeft: "20px" }} /> Parties. The parties to these
        Terms of Use are you, and the owner of this codevmedia.com website
        business, Codev Media ("Codev Media"). All references to "we", "us",
        "our", this "website" or this "site" shall be construed to mean this
        website business and Codev Media. <br />
        <br />
        2.
        <span style={{ paddingLeft: "20px" }} /> Use and Restrictions. Subject
        to these Terms of Use and our Privacy Policy, you may use the public
        areas of this site, but only for your own internal purposes. You agree
        not to access (or attempt to access) this site by any means other than
        through the interface we provide, unless you have been specifically
        allowed to do so in a separate agreement. You agree not to access (or
        attempt to access) this site through any automated means (including use
        of scripts or web crawlers), and you agree to comply with the
        instructions set out in any robots.txt file present on this site. You
        are not authorized to (i) resell, sublicense, transfer, assign, or
        distribute the site, its services or content; (ii) modify or make
        derivative works based on the site, its services or content; or (iii)
        "frame" or "mirror" the site, its services or content on any other
        server or Internet-enabled device. All rights not expressly granted in
        this Agreement are reserved by us and our licensors.
        <br />
        <br />
        3.
        <span style={{ paddingLeft: "20px" }} /> Modification. We reserve the
        right to modify these Terms of Use at any time, and without prior
        notice, by posting an amended Terms of Use that is always accessible
        through the Terms of Use link on this site's home page. You should
        scroll to the bottom of this page periodically to review material
        modifications and their effective dates. YOUR CONTINUED USE OF THIS SITE
        FOLLOWING OUR POSTING OF A MODIFICATION NOTICE OR NEW TERMS OF USE ON
        THIS SITE WILL CONSTITUTE BINDING ACCEPTANCE OF THE MODIFICATION OR NEW
        TERMS OF USE.
        <br />
        <br />
        4.
        <span style={{ paddingLeft: "20px" }} /> Monitoring. We reserve the
        right, but not the obligation, to monitor your access and use of this
        site without notification to you. We may record or log your use in a
        manner as set out in our Privacy Policy that is accessible though the
        Privacy Policy link on this site's home page. <br />
        <br />
        5.
        <span style={{ paddingLeft: "20px" }} /> Separate Agreements. You may
        acquire products, services and/or content from this site. We reserve the
        right to require that you agree to separate agreements as a condition of
        your use and/or purchase of such products, services and/or content.
        <br />
        <br />
        6.
        <span style={{ paddingLeft: "20px" }} /> Ownership. The material
        provided on this site is protected by law, including, but not limited
        to, United States copyright law and international treaties. The
        copyrights and other intellectual property in the content of this site
        is owned by us and/or others. Except for the limited rights granted
        herein, all other rights are reserved. <br />
        <br />
        7.
        <span style={{ paddingLeft: "20px" }} /> Warranty Disclaimers. EXCEPT AS
        MAY BE PROVIDED IN ANY SEPARATE WRITTEN AGREEMENTS SIGNED BY THE
        PARTIES, THE SERVICES, CONTENT, AND/OR PRODUCTS ON THIS SITE ARE
        PROVIDED "AS-IS", AND NEITHER WE NOR ANY OF OUR LICENSORS MAKE ANY
        REPRESENTATION OR WARRANTY WITH RESPECT TO SUCH PRODUCTS, SERVICES,
        AND/OR CONTENT. EXCEPT AS MAY BE PROVIDED IN ANY SEPARATE WRITTEN
        AGREEMENT SIGNED BY THE PARTIES OR SEPARATE AGREEMENT ORIGINATING FROM
        THIS SITE, THIS SITE AND ITS LICENSORS SPECIFICALLY DISCLAIM, TO THE
        FULLEST EXTENT PERMITTED BY LAW, ANY AND ALL WARRANTIES, EXPRESS OR
        IMPLIED, RELATING TO THIS SITE OR PRODUCTS, SERVICES AND/OR CONTENT
        ACQUIRED FROM THIS SITE, INCLUDING BUT NOT LIMITED TO, IMPLIED
        WARRANTIES OF MERCHANTABILITY, COMPLETENESS, TIMELINESS, CORRECTNESS,
        NON-INFRINGEMENT, OR FITNESS FOR ANY PARTICULAR PURPOSE. THIS SITE AND
        ITS LICENSORS DO NOT REPRESENT OR WARRANT THAT THIS SITE, ITS PRODUCTS,
        SERVICES, AND/OR CONTENT: (A) WILL BE SECURE, TIMELY, UNINTERRUPTED OR
        ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE,
        SYSTEM OR DATA, (B) WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR (C)
        WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. . THESE DISCLAIMERS
        CONSTITUTE AN ESSENTIAL PART OF THIS AGREEMENT. NO PURCHASE OR USE OF
        THE ITEMS OFFERED BY THIS SITE IS AUTHORIZED HEREUNDER EXCEPT UNDER
        THESE DISCLAIMERS. IF IMPLIED WARRANTIES MAY NOT BE DISCLAIMED UNDER
        APPLICABLE LAW, THEN ANY IMPLIED WARRANTIES ARE LIMITED IN DURATION TO
        THE PERIOD REQUIRED BY APPLICABLE LAW. SOME STATES OR JURISDICTIONS DO
        NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE
        ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
        <br />
        <br />
        8.
        <span style={{ paddingLeft: "20px" }} /> Warranty Disclaimers. EXCEPT AS
        MAY BE PROVIDED IN ANY SEPARATE WRITTEN AGREEMENTS SIGNED BY THE
        PARTIES, THE SERVICES, CONTENT, AND/OR PRODUCTS ON THIS SITE ARE
        PROVIDED "AS-IS", AND NEITHER WE NOR ANY OF OUR LICENSORS MAKE ANY
        REPRESENTATION OR WARRANTY WITH RESPECT TO SUCH PRODUCTS, SERVICES,
        AND/OR CONTENT. EXCEPT AS MAY BE PROVIDED IN ANY SEPARATE WRITTEN
        AGREEMENT SIGNED BY THE PARTIES OR SEPARATE AGREEMENT ORIGINATING FROM
        THIS SITE, THIS SITE AND ITS LICENSORS SPECIFICALLY DISCLAIM, TO THE
        FULLEST EXTENT PERMITTED BY LAW, ANY AND ALL WARRANTIES, EXPRESS OR
        IMPLIED, RELATING TO THIS SITE OR PRODUCTS, SERVICES AND/OR CONTENT
        ACQUIRED FROM THIS SITE, INCLUDING BUT NOT LIMITED TO, IMPLIED
        WARRANTIES OF MERCHANTABILITY, COMPLETENESS, TIMELINESS, CORRECTNESS,
        NON-INFRINGEMENT, OR FITNESS FOR ANY PARTICULAR PURPOSE. THIS SITE AND
        ITS LICENSORS DO NOT REPRESENT OR WARRANT THAT THIS SITE, ITS PRODUCTS,
        SERVICES, AND/OR CONTENT: (A) WILL BE SECURE, TIMELY, UNINTERRUPTED OR
        ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE,
        SYSTEM OR DATA, (B) WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR (C)
        WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THESE DISCLAIMERS
        CONSTITUTE AN ESSENTIAL PART OF THIS AGREEMENT. NO PURCHASE OR USE OF
        THE ITEMS OFFERED BY THIS SITE IS AUTHORIZED HEREUNDER EXCEPT UNDER
        THESE DISCLAIMERS. IF IMPLIED WARRANTIES MAY NOT BE DISCLAIMED UNDER
        APPLICABLE LAW, THEN ANY IMPLIED WARRANTIES ARE LIMITED IN DURATION TO
        THE PERIOD REQUIRED BY APPLICABLE LAW. SOME STATES OR JURISDICTIONS DO
        NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE
        ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
        <br />
        <br />
        9.
        <span style={{ paddingLeft: "20px" }} /> Limitation of Liability. IN NO
        EVENT SHALL THIS SITE AND/OR ITS LICENSORS BE LIABLE TO ANYONE FOR ANY
        DIRECT, INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL,
        CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF
        DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF,
        OR IN ANY WAY CONNECTED WITH THIS SITE, ITS PRODUCTS, SERVICES, AND/OR
        CONTENT, ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION, REGARDLESS OF
        CAUSE, EVEN IF THIS SITE OR OUR LICENSORS HAVE BEEN PREVIOUSLY ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES.
        <br />
        <br />
        10.
        <span style={{ paddingLeft: "20px" }} /> Links to This Site. We grant to
        you a limited, revocable, and nonexclusive right to create a hyperlink
        to this site provided that the link does not portray us or our products
        or services in a false, misleading, derogatory, or offensive matter. You
        may not use any logo, trademark, or tradename that may be displayed on
        this site or other proprietary graphic image in the link without our
        prior written consent.
        <br />
        <br />
        11.
        <span style={{ paddingLeft: "20px" }} /> Links to Third-Party Websites.
        We do not review or control third party websites that link to or from
        this site, and we are not responsible for their content, and do not
        represent that their content is accurate or appropriate. Your use of any
        third party site is on your own initiative and at your own risk, and may
        be subject to the other sites' terms of use and privacy policy.
        <br />
        <br />
        12.
        <span style={{ paddingLeft: "20px" }} /> Participation In Promotions of
        Advertisers. You may enter into correspondence with or participate in
        promotions of advertisers promoting their products, services or content
        on this site. Any such correspondence or participation, including the
        delivery of and the payment for products, services or content, are
        solely between you and each such advertiser.
        <br />
        <br />
        13.
        <span style={{ paddingLeft: "20px" }} /> Consumer Rights Information;
        California Civil Code Section 1789.3. If this site charges for services,
        products, content, or information, pricing information will be posted as
        part of the ordering process for this site. We maintain specific contact
        information including an e-mail address for notifications of complaints
        and for inquiries regarding pricing policies in accordance with
        California Civil Code Section 1789.3. All correspondence should be
        addressed to our agent for notice at the following address:
        <br />
        <br />
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} /> Notification of Consumer Rights
        Complaint or Pricing Inquiry:
        <br />
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} /> Codev Media
        <br />
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} /> Oklahoma City, Oklahoma, 73162
        <br />
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} /> Contact: info@codevmedia.com
        <br />
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} /> Telephone:(405)509-9218
        <br />
        <br />
        You may contact us with complaints and inquiries regarding pricing and
        we will investigate those matters and respond to the inquiries.
        <br />
        <br />
        The Complaint Assistance Unit of the Division of Consumer Services of
        the Department of Consumer Affairs may be contacted in writing at 1020
        N. Street, #501, Sacramento, CA 95814, or by telephone at
        1-916-445-1254.
        <br />
        <br />
        14.
        <span style={{ paddingLeft: "20px" }} /> Arbitration. Except for actions
        to protect intellectual property rights and to enforce an arbitrator's
        decision hereunder, all disputes, controversies, or claims arising out
        of or relating to this Agreement or a breach thereof shall be submitted
        to and finally resolved by arbitration under the rules of the American
        Arbitration Association ("AAA") then in effect. There shall be one
        arbitrator, and such arbitrator shall be chosen by mutual agreement of
        the parties in accordance with AAA rules. The arbitration shall take
        place in Oklahoma City, Oklahoma, USA, and may be conducted by telephone
        or online. The arbitrator shall apply the laws of the State of Oklahoma,
        USA to all issues in dispute. The controversy or claim shall be
        arbitrated on an individual basis, and shall not be consolidated in any
        arbitration with any claim or controversy of any other party. The
        findings of the arbitrator shall be final and binding on the parties,
        and may be entered in any court of competent jurisdiction for
        enforcement. Enforcements of any award or judgment shall be governed by
        the United Nations Convention on the Recognition and Enforcement of
        Foreign Arbitral Awards. Should either party file an action contrary to
        this provision, the other party may recover attorney's fees and costs up
        to $1000.00.
        <br />
        <br />
        15.
        <span style={{ paddingLeft: "20px" }} /> Jurisdiction and Venue. The
        courts of Oklahoma County in the State of Oklahoma, USA and the nearest
        U.S. District Court in the State of Oklahoma shall be the exclusive
        jurisdiction and venue for all legal proceedings that are not arbitrated
        under these Terms of Use.
        <br />
        <br />
        16.
        <span style={{ paddingLeft: "20px" }} /> Controlling Law. This Agreement
        shall be construed under the laws of the State of Oklahoma, USA,
        excluding rules regarding conflicts of law. The application the United
        Nations Convention of Contracts for the International Sale of Goods is
        expressly excluded.
        <br />
        <br />
        17.
        <span style={{ paddingLeft: "20px" }} /> Severability. If any provision
        of these terms is declared invalid or unenforceable, such provision
        shall be deemed modified to the extent necessary and possible to render
        it valid and enforceable. In any event, the unenforceability or
        invalidity of any provision shall not affect any other provision of
        these terms, and these terms shall continue in full force and effect,
        and be construed and enforced, as if such provision had not been
        included, or had been modified as above provided, as the case may be.
        <br />
        <br />
        18.
        <span style={{ paddingLeft: "20px" }} /> Force Majeure. We shall not be
        liable for damages for any delay or failure of delivery arising out of
        causes beyond our reasonable control and without our fault or
        negligence, including, but not limited to, Acts of God, acts of civil or
        military authority, fires, riots, wars, embargoes, Internet disruptions,
        hacker attacks, or communications failures.
        <br />
        <br />
        19.
        <span style={{ paddingLeft: "20px" }} /> Privacy. Please review this
        site's Privacy Policy which also governs your visit to this site. Our
        Privacy Policy is always accessible on our site's home page.
        <br />
        <br />
        --
        <br />
        <br />
        »Material Modifications« Since January 1, 2020: none.
        <br />
        <br />
        Notice: This document is Copyright © Chip Cooper of the law firm of
        Jones &amp; Haley, P.C., and licensed for use by the owner of this
        website under distribution rights granted to FTCGuardian.com. All rights
        reserved worldwide. No part of this document may be copied, reprinted,
        reproduced, or transmitted in any form or by any means without the prior
        written permission of the copyright owner.
        <br />
        <br />
      </div>
      <br />
      <hr />
      <h3>Document Modification</h3>
      <p>
        We do permit user modifications. You are reminded that your
        modifications could change desired legal results, and for this reason,
        it's recommended that you consult with a qualified attorney.
      </p>
    </Container>
  )
}

export default TOS
